import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GlobalService } from '../service/global.service';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ReportModel } from '../model/account.model';

@Injectable({
  providedIn: 'root'
})
export class PurchaseregisterService {
   
  
  public regionchangesubject = new Subject<any>();
  constructor(private http: HttpClient, private globalService: GlobalService) { }
  LoadGoodsInBranchInRegionChange(selectedRegion) {
    this.regionchangesubject.next(selectedRegion);
  }
  getCountryDelivery(): any {
    return this.http.get(this.globalService.baseUrl + '/api/GetCountry');
  }
  getIncoTerm(): any {
    return this.http.get(this.globalService.baseUrl + '/api/GetIncoTerm');
  }
  getStatisticalPeriod(): any {
    return this.http.get(this.globalService.baseUrl + '/api/GetStatPeriod');
  }
  getSubOrderType(): any {
    return this.http.get(this.globalService.baseUrl + '/api/GetSubOrderType');
  }
  getCurrency(): any {
    return this.http.get(this.globalService.baseUrl + '/api/GetCurrency');
  }
  getProductGroup(): any {
    return this.http.get(this.globalService.baseUrl + '/api/GetProductGroup');;
  }
  getContact(posupplierid): any {
    let params = new HttpParams().set("posupplierid", posupplierid);
    return this.http.get(this.globalService.baseUrl + '/api/getContact', { params: params });
  }
  getContacts(): any {
    return this.http.get(this.globalService.baseUrl + '/api/getContacts');
  }
  getPOSupplier(): any {
    return this.http.get(this.globalService.baseUrl + '/api/GetPOSupplier');
  }
  getSupplier(): any {
    return this.http.get(this.globalService.baseUrl + '/api/GetSupplier');
  }
  getShop(regionId, goodsInBranchId): any {
    
    let params = new HttpParams().set("regionId", regionId).set("goodsInBranchId", goodsInBranchId);
    return this.http.get(this.globalService.baseUrl + '/api/GetShop', { params: params });
  }
  getShopFilter(regionId, goodsInBranchId): any {
    let params = new HttpParams().set("regionId", regionId).set("goodsInBranchId", goodsInBranchId);
    return this.http.get(this.globalService.baseUrl + '/api/GetFilterShop', { params: params });
  }
  getGoodsInBranch(regionId): any {
    let params = new HttpParams().set("regionId", regionId);
    return this.http.get(this.globalService.baseUrl + '/api/GetGoodsInBranch', { params: params });
  }
  getOrderType(): any {
    return this.http.get(this.globalService.baseUrl + '/api/getOrderType');
  }
  getBuyer(): any {
    return this.http.get(this.globalService.baseUrl + '/api/getBuyer');
  }
  SaveContacts(form: NgForm): any {
    return this.http.post(this.globalService.baseUrl + '/api/SaveContacts', form);
  }
  SaveBuyers(form: NgForm): any {
    return this.http.post(this.globalService.baseUrl + '/api/SaveBuyers', form);

  }
  SavePurchaseRegister(form): any {
    
    return this.http.post(this.globalService.baseUrl + '/api/SavePurchaseRegister', form);
  }
  SaveFiles(formData): any {
    return this.http.post(this.globalService.baseUrl + '/api/SaveFiles', formData);
  }
  getUserNames(): any {
    return this.http.get(this.globalService.baseUrl + '/api/GetUserNames');
  }
  getCurrencyValue(): any {
    return this.http.get(this.globalService.baseUrl + '/api/GetCurrencyValue');
  }
  RemoveImage(file, OrderIndex): any {
    const body: any = {
      FileName: file,
      OrderIndex: OrderIndex
    }
    return this.http.post(this.globalService.baseUrl + '/api/RemoveImage', body);
  }
  DeletePurchaseReport(OrderIndex: any): any {
    const body: any = {
      OrderIndex: OrderIndex
    }
    return this.http.post(this.globalService.baseUrl + '/api/DeletePurchaseReport', body);
  }

  DeletePurchaseOrder(OrderIndex: any): any {
    const body: any = {
      OrderIndex: OrderIndex
    }
    return this.http.post(this.globalService.baseUrl + '/api/DeletePurchaseOrder', body);
  }

  ForDB(): any {
    return this.http.get(this.globalService.baseUrl + '/api/ForDB');
  }

  getPaymentTerm(): any {
    return this.http.get(this.globalService.baseUrl + '/api/GetPaymentTerm');
  }

  SavePORegistration(form): any {

    return this.http.post(this.globalService.baseUrl + '/api/SavePORegistration', form);
  }

  SavePOFiles(formData): any {
    return this.http.post(this.globalService.baseUrl + '/api/SavePOFiles', formData);
  }

  SavePOShop(form): any {

    return this.http.post(this.globalService.baseUrl + '/api/SavePOShop', form);
  }

  DeletePOShop(OrderIndex): any {
    let params = new HttpParams().set("OrderIndex", OrderIndex);
    return this.http.post(this.globalService.baseUrl + '/api/DeletePOShop',null, { params: params });
  }

  GetUnitShop(branchId): any {
    let params = new HttpParams().set("branchId", branchId); 
    return this.http.get(this.globalService.baseUrl + '/api/GetUnitShop', { params: params });
  }

  RemoveOrderFile(file, OrderIndex): any {
    const body: any = {
      FileName: file,
      OrderIndex: OrderIndex
    }
    return this.http.post(this.globalService.baseUrl + '/api/RemoveOrderFile', body);
  }

  getGender(): any {
    return this.http.get(this.globalService.baseUrl + '/api/GetGender');
  }
}

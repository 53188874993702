import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { PurchaseregisterService } from '../service/purchaseregister.service';
import { NgForm } from '@angular/forms';
import { GlobalService } from '../service/global.service';
import { MenuService } from '../service/menu.service';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ColDef, GridOptions, GridSizeChangedEvent, GridReadyEvent } from 'ag-grid-community';
import { ReportService } from '../service/report.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EmployeeService } from '../service/employee.service';
import { PoRegistrationModel, PoShopModel, OrderFilesWithURL } from '../model/po-registration.model';
import { PoUserLockedCheckboxComponent } from '../components/po-user-locked-checkbox/po-user-locked-checkbox.component';
import { PoReOrderCheckboxComponent } from '../components/po-re-order-checkbox/po-re-order-checkbox.component';
declare var $;
declare var bootbox: any;

@Component({
  selector: 'app-poregistration',
  templateUrl: './poregistration.component.html',
  styleUrls: ['./poregistration.component.css']
})
export class PoregistrationComponent implements OnInit {
  
  defaultbrand = 1927; //2581; //set default brand on page load
  bsValue = new Date();
  maxDate = new Date();
  DeliveryFromTo: Date[];
  register: any = {};
  pageName: string = 'Registration';
  goodsInBranchList: any[] = [];
  shopList: any = [];
  selectedShops: PoShopModel[] = [];
  currencyList: any = [];
  genderList: any = [];
  supplierList: any = [];
  paymenttermList: any = [];
  statisticalPeriodList: any[] = [];
  incotermList: any = [];
  orderCreatedEmployeeInfo: String;
  errorMessage: string = "";
  uploadResult: any = "";
  filesToUpload: Array<File>=[];
  selectedFileNames: any = [];
  public isLoadingData: Boolean = false;
  @ViewChild('fileUpload') fileUploadVar: ElementRef;
  userRoles: any;  
  res: Array<string>;
  showpurchaseregister: boolean = true;
  showpurchaseregisteralert: boolean = false;
  isChecked: boolean;
  orderFiles: OrderFilesWithURL[] = [];
  orderIndexToEdit: string = "";

  isReOrderCheckboxClicked = false; // Flag to check if checkbox was clicked
  isUserLockedCheckboxClicked = false; // Flag to check if checkbox was clicked
  previousSelectedRow = null;
  
  

  // ag-grid Settings
  private gridApi;
  private gridColumnApi;
  purchaseOrder: PoRegistrationModel[];
  purchaseOrderShops: PoShopModel[]=[];
  gridOptions: GridOptions;
  rowData = [];
  columnDefs = [
    { field: "OrderIndex", headerName: "OrderIndex", sort: 'desc', hide: true,flex:1 },
    {
      field: "Currency", flex: 1, headerTooltip:"Currency", cellEditor: 'agSelectCellEditor',
      cellEditorParams: () => ({ values: this.getCurrencies() }) ,
    },
    {
      field: "TotalValue", flex: 1, headerName: "NetValue", headerTooltip: "NetValue"
    },
    { field: "TotalPCS", flex: 1, headerName: "PCS", headerTooltip: "PCS" },
    { field: "SKU", flex: 1, headerName: "TotalSKUs", headerTooltip: "TotalSKUs" },
    { field: "OrderNo", flex: 1, headerName: "OrderNo", headerTooltip: "OrderNo" },
    {
      field: "Gender", flex: 1, headerTooltip: "Gender", cellEditor: 'agSelectCellEditor',
      cellEditorParams: () => ({ values: this.getGender() }),
    },
    {
      headerName: 'ReOrder',
      field: 'ReOrder',
      flex: 1,
      headerTooltip: "ReOrder",
      cellRenderer: "PoReOrderCheckboxComponent",
    },
    { field: "InternalPONumber", flex: 1, headerName: "Internal PO", headerTooltip: "Internal PO" },
    {
      headerName: 'UserLocked',
      field: 'IsUserLocked',
      flex: 1,
      headerTooltip: "UserLocked",
      cellRenderer: "PoUserLockedCheckboxComponent",
    }
  ];
  defaultColDef: ColDef = {
    // set every column width
    //width: 110,
    editable: true,
    sortable: true,
    filter: true,
    resizable:true,
  };

  frameworkComponents = {
    PoUserLockedCheckboxComponent: PoUserLockedCheckboxComponent,
    PoReOrderCheckboxComponent: PoReOrderCheckboxComponent
  };

 


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private purchaseregisterservice: PurchaseregisterService,
    private toastr: ToastrService,
    private globalService: GlobalService,
    private menuService: MenuService,
    private localeService: BsLocaleService,
    private reportService: ReportService,
    private employeeService: EmployeeService,
    private ngxService: NgxUiLoaderService
  ) {
    this.maxDate.setDate(this.maxDate.getDate() + 7);
    defineLocale('en-gb', enGbLocale);
    debugger;
    this.localeService.use('en-gb');
    this.orderIndexToEdit = route.snapshot.params.id == undefined ? "" : route.snapshot.params.id;
    //console.log(this.orderToEdit);
  }

  ngOnInit() {
    this.globalService.getUserInfo().subscribe((data: any) => {      
      if (data != undefined) {
        this.userRoles = data.Roles;
        this.userRoles = this.globalService.getUserRoles(this.userRoles, this.globalService.globalporegistrationpage);

        if (data.PasswordChangedDays <= this.globalService.globalPasswordChangeDays && this.userRoles != undefined && this.userRoles.Read == true) {
          debugger;
          this.register.BrandId = this.orderIndexToEdit == "" ? this.defaultbrand : null;
          this.register.SeasonId = null;
          this.LoadSupplier();
          this.LoadStatisticalPeriod();
          this.LoadGoodsInBranch(null);
          this.LoadShop(null);
          this.LoadCurrency();
          this.LoadGender();
          this.LoadIncoterm();
          this.LoadPurchaseOrders(this.orderIndexToEdit, null, null, this.register.BrandId, this.register.SeasonId, null, null);
          if (this.orderIndexToEdit == "") {
            this.ClearRegisterFields();
          }
        }
        else {
          this.showpurchaseregister = false;
          this.showpurchaseregisteralert = true;
        }

      }
    });
    this.menuService.HideUnitMenu(false);
    this.menuService.HideChart(false);
    this.menuService.ShowRegionMenu(false);
   // $('#DeliveryFromTo').daterangepicker();

    this.gridOptions = <GridOptions>{
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      defaultColDef: this.defaultColDef,
      //editType : 'fullRow',
      rowSelection: 'single',
      columnTypes: {
        numberColumn: {
          width: 83,
          filter: "agNumberColumnFilter"
        },
        nonEditableColumn: { editable: false },
      },
      context: { componentParent: this },
      stopEditingWhenGridLosesFocus: true,
      suppressScrollOnNewData: true,
      // set background colour on even rows again, this looks bad, should be using CSS classes
      getRowStyle: params => {
        if (params.node.rowIndex === params.api.getLastDisplayedRow()) {
          return {
            background: 'lavender',
            border: 'groove'
          };
        }
      },

    }

  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
   
  }

  
  //this make grid responsive to div size
  onGridSizeChanged(params: GridSizeChangedEvent) {
    
  }

  handleRowDataChanged(event) {
    debugger;
    const index = event.api.getLastDisplayedRow();
    //this.gridOptions.api.ensureIndexVisible(index, 'bottom');
  }

  onSelectionChanged(params) {
    this.orderFiles = [];
    this.filesToUpload = [];
    //this.register.IsUserLocked = null;
    var selectedRows = params.api.getSelectedRows();
    if (selectedRows[0].OrderIndex) {
      debugger;
      const currentSelectedRow = selectedRows[0].OrderIndex;
      let selectedPurchaseOrder = this.purchaseOrder.find(i => i.OrderIndex === selectedRows[0].OrderIndex);
      this.selectedShops = this.purchaseOrderShops.filter(i => i.OrderIndex === selectedRows[0].OrderIndex).map(obj => obj);

      this.LoadShop(selectedPurchaseOrder.BranchId);

      this.register.Incoterms = selectedPurchaseOrder.Incoterms;
      this.register.DeliveryFromDate = selectedPurchaseOrder.DeliveryFromDate;
      this.register.DeliveryToDate = selectedPurchaseOrder.DeliveryToDate;
      this.register.DeliveryFromTo = [new Date(selectedPurchaseOrder.DeliveryFromDate), new Date(selectedPurchaseOrder.DeliveryToDate)];
      this.register.OrderDiscount = selectedPurchaseOrder.OrderDiscount;
      this.register.PaymentTerm = selectedPurchaseOrder.PaymentTerm;
      this.register.Comment = selectedPurchaseOrder.Comment;
      this.register.BranchId = selectedPurchaseOrder.BranchId;
      this.register.POSupplierContactId = selectedPurchaseOrder.POSupplierContactId;
      this.register.OrderTypeId = selectedPurchaseOrder.OrderTypeId;
      this.register.POSupplierId = selectedPurchaseOrder.POSupplierId;
      this.register.Gender = selectedPurchaseOrder.Gender;
      debugger;
      if ((this.isEventFromReOrderCheckbox()) && (!this.previousSelectedRow || this.previousSelectedRow === currentSelectedRow)) {
        this.register.ReOrder = this.register.ReOrder;
      }
      else {
        this.register.ReOrder = selectedPurchaseOrder.ReOrder;
      }
      //this.register.ReOrder = this.register.ReOrder == undefined ? selectedPurchaseOrder.ReOrder : this.register.ReOrder;
     

     

      //// Get all shopCheckboxes
      //const shopCheckboxes = Object.values(document.querySelectorAll<HTMLInputElement>('input[name="ShopId"]'));
      //shopCheckboxes.map(c => c.checked = false);
      //shopCheckboxes.map(c => {
      //  if (this.selectedShops.find(s => String(s.ShopNo) === c.defaultValue)) {
      //    c.checked = true;
      //  }
      //});

      setTimeout(() => {
        this.ScrollToSelectedBranchRadio();

      });

      setTimeout(() => {
        this.ScrollToSelectedShopCheckbox();

      },1000);

     
      this.register.OrderIndex = selectedPurchaseOrder.OrderIndex;      
      this.register.OrderNo = selectedPurchaseOrder.OrderNo;
      this.register.BrandId = selectedPurchaseOrder.BrandId;
      this.register.SeasonId = selectedPurchaseOrder.SeasonId;      
      this.register.SKU = selectedPurchaseOrder.SKU;
      this.register.InternalPONumber = selectedPurchaseOrder.InternalPONumber;
      this.register.Currency = selectedPurchaseOrder.Currency;
      this.register.TotalPCS = selectedPurchaseOrder.TotalPCS;
      this.register.TotalValue = selectedPurchaseOrder.TotalValue;
      debugger;
      //this.register.IsUserLocked = this.register.IsUserLocked == undefined ? selectedPurchaseOrder.IsUserLocked : this.register.IsUserLocked;
      if ((this.isEventFromUserLockedCheckbox()) && (!this.previousSelectedRow || this.previousSelectedRow === currentSelectedRow)) {
        this.register.IsUserLocked = this.register.IsUserLocked;
      }
      else {
        this.register.IsUserLocked = selectedPurchaseOrder.IsUserLocked;
      }
      this.previousSelectedRow = currentSelectedRow;
     
      this.register.UpdatedBy = selectedPurchaseOrder.UpdatedBy;
      this.register.OrderUpdatedDate = selectedPurchaseOrder.OrderUpdatedDate;

      if (selectedPurchaseOrder.IsUserLocked) {
        this.employeeService.GetEmployeeInfoByUserId(selectedPurchaseOrder.UpdatedBy).subscribe((data: any) => {
          this.orderCreatedEmployeeInfo = data.Employee_Name + '-- Department:' + data.Department_Name;
        });
      };

      if (selectedPurchaseOrder.FileName1) {
        let file: OrderFilesWithURL = {
          OrderIndex: selectedPurchaseOrder.OrderIndex,
          FileColumnName: "FileName1",
          FileName: selectedPurchaseOrder.FileName1,
          FileURL: selectedPurchaseOrder.FilePath + encodeURIComponent(selectedPurchaseOrder.FileName1)
        }
        this.orderFiles.push(file);
      }
      if (selectedPurchaseOrder.FileName2) {
        let file: OrderFilesWithURL = {
          OrderIndex: selectedPurchaseOrder.OrderIndex,
          FileColumnName: "FileName2",
          FileName: selectedPurchaseOrder.FileName2,
          FileURL: selectedPurchaseOrder.FilePath + encodeURIComponent(selectedPurchaseOrder.FileName2)
        }
        this.orderFiles.push(file);
      }
      if (selectedPurchaseOrder.FileName3) {
        let file: OrderFilesWithURL = {
          OrderIndex: selectedPurchaseOrder.OrderIndex,
          FileColumnName: "FileName3",
          FileName: selectedPurchaseOrder.FileName3,
          FileURL: selectedPurchaseOrder.FilePath + encodeURIComponent(selectedPurchaseOrder.FileName3)
        }
        this.orderFiles.push(file);
      }
      if (selectedPurchaseOrder.FileName4) {
        let file: OrderFilesWithURL = {
          OrderIndex: selectedPurchaseOrder.OrderIndex,
          FileColumnName: "FileName4",
          FileName: selectedPurchaseOrder.FileName4,
          FileURL: selectedPurchaseOrder.FilePath + encodeURIComponent(selectedPurchaseOrder.FileName4)
        }
        this.orderFiles.push(file);
      }
      if (selectedPurchaseOrder.FileName5) {
        let file: OrderFilesWithURL = {
          OrderIndex: selectedPurchaseOrder.OrderIndex,
          FileColumnName: "FileName5",
          FileName: selectedPurchaseOrder.FileName5,
          FileURL: selectedPurchaseOrder.FilePath + encodeURIComponent(selectedPurchaseOrder.FileName5)
        }
        this.orderFiles.push(file);
      }   
       
      
      
    }
    else {
      this.register.OrderIndex = "";
      this.register.OrderNo = "";
      this.register.POSupplierContactId = "";
      this.register.OrderTypeId = "";
      this.register.POSupplierId = "";
      this.register.IsUserLocked = undefined;
      this.register.ReOrder = undefined;
      this.register.Gender = "";
      
      this.register.OrderNo = selectedRows[0].OrderNo !== undefined ? selectedRows[0].OrderNo : "";
      this.register.SKU = selectedRows[0].SKU !== undefined ? selectedRows[0].SKU : "";
      this.register.InternalPONumber = selectedRows[0].InternalPONumber !== undefined ? selectedRows[0].InternalPONumber : "";
      this.register.Currency = selectedRows[0].Currency !== undefined ? selectedRows[0].Currency : "";
      this.register.TotalPCS = selectedRows[0].TotalPCS !== undefined ? selectedRows[0].TotalPCS : "";
      this.register.TotalValue = selectedRows[0].TotalValue !== undefined ? selectedRows[0].TotalValue : "";
      
    }
  }

  onCellEditingStopped(params) {
    debugger;
    this.register.Currency = params.data.Currency;
    this.register.TotalPCS = params.data.TotalPCS;
    this.register.SKU = params.data.SKU;
    this.register.OrderNo = params.data.OrderNo;
    this.register.InternalPONumber = params.data.InternalPONumber;
    this.register.Gender = params.data.Gender;
    if (params.data.TotalValue) {
      if (params.data.TotalValue.toString().includes(',')) {
        this.register.TotalValue = params.data.TotalValue.replace(',', '.');
      }
      else {
        this.register.TotalValue = params.data.TotalValue == 0 ? undefined : params.data.TotalValue;
      }
    }
    
  }


  LoadPurchaseOrders(orderIndex, deliveryFromDate, deliveryToDate, brandId, seasonId, branchId, shopSelected): any {
    this.ngxService.start();
    var searchPurchaseOrders = {
      OrderIndex: orderIndex,
      DeliveryFromDate: deliveryFromDate,
      DeliveryToDate: deliveryToDate,
      //BrandId: brandId == null ? this.defaultbrand : brandId,
      BrandId: brandId,
      SeasonId: seasonId,
      BranchId: branchId,
      ShopNo: shopSelected

    };
    this.reportService.GetReportPurchaseOrders(searchPurchaseOrders).subscribe((data: any) => {
      debugger;
      this.purchaseOrder = [];
      this.purchaseOrderShops = [];
      this.purchaseOrder = data.map(d => {
        return {
          OrderIndex: d.OrderIndex,
          OrderNo: d.OrderNo,
          BrandId: d.BrandId,
          SeasonId: d.SeasonId,
          BranchId: d.BranchId,
          SKU: d.SKU,
          InternalPONumber: d.InternalPONumber,
          Currency: d.Currency,
          TotalPCS: d.TotalPCS,
          TotalValue: d.TotalValue,
          // UnitNo: d.UnitNo,
          Incoterms: d.Incoterms,
          DeliveryFromDate: d.DeliveryFromDate,
          DeliveryToDate: d.DeliveryToDate,
          OrderDiscount: d.OrderDiscount,
          PaymentTerm: d.PaymentTerm,
          Comment: d.Comment,
          FilePath: d.FilePath,
          FileName1: d.FileName1,
          FileName2: d.FileName2,
          FileName3: d.FileName3,
          FileName4: d.FileName4,
          FileName5: d.FileName5,
          IsUserLocked: d.IsUserLocked,
          OrderCreatedDate: d.OrderCreatedDate,
          OrderUpdatedDate: d.OrderUpdatedDate,
          CreatedBy: d.CreatedBY,
          UpdatedBy: d.UpdatedBY,
          POSupplierContactId: d.POSupplierContactId,
          OrderTypeId: d.OrderTypeId,
          POSupplierId: d.POSupplierId,
          ReOrder: d.ReOrder,
          Gender : d.Gender

        }
      });
      if (this.purchaseOrder.length > 0) {
        this.register.SeasonId = Math.max.apply(null, this.purchaseOrder.map(function (o) { return o.SeasonId; }));  // to set default for stat peiod on page load
        this.register.BrandId = Math.max.apply(null, this.purchaseOrder.map(function (o) { return o.BrandId; }));  // to set default for stat peiod on page load
      }
      this.purchaseOrder = Object.values(this.purchaseOrder.reduce((acc, obj) => ({ ...acc, [obj.OrderIndex]: obj }), {}));
      this.rowData = this.purchaseOrder;
      if (this.orderIndexToEdit == "") {
        this.rowData.push({});
      };

      /* Map Purchase Order Shops*/
      data.map(val => {
        val.PurchaseOrderShops.map(d => {
          if (d != null) {
            let poShop: PoShopModel = {
              ShopNo: d.ShopNo,
              OrderIndex: d.OrderIndex
            }
            if (!this.purchaseOrderShops.some(e => e.OrderIndex === poShop.OrderIndex && e.ShopNo === poShop.ShopNo)) {
              this.purchaseOrderShops.push(poShop);
            }
          }

        });
      });

      if (this.purchaseOrder.length == 1 && this.orderIndexToEdit != "") {
        debugger;
        var poToEdit = this.purchaseOrder[0];
        this.register.BranchId = poToEdit.BranchId;
        this.LoadShop(poToEdit.BranchId);
        this.register.Incoterms = poToEdit.Incoterms;
        this.register.OrderDiscount = poToEdit.OrderDiscount;
        this.register.OrderIndex = poToEdit.OrderIndex;
        this.register.OrderNo = poToEdit.OrderNo;
        this.register.Comment = poToEdit.Comment;
        this.register.PaymentTerm = poToEdit.PaymentTerm;
        this.register.IsUserLocked = poToEdit.IsUserLocked;
        this.register.POSupplierContactId = poToEdit.POSupplierContactId;
        this.register.OrderTypeId = poToEdit.OrderTypeId;
        this.register.POSupplierId = poToEdit.POSupplierId;
        this.register.UpdatedBy = poToEdit.UpdatedBy;
        this.register.OrderUpdatedDate = poToEdit.OrderUpdatedDate;
        this.register.DeliveryFromDate = poToEdit.DeliveryFromDate;
        this.register.DeliveryToDate = poToEdit.DeliveryToDate;
        this.register.DeliveryFromTo = [new Date(poToEdit.DeliveryFromDate), new Date(poToEdit.DeliveryToDate)];

        this.register.SKU = poToEdit.SKU;
        this.register.InternalPONumber = poToEdit.InternalPONumber;
        this.register.Currency = poToEdit.Currency;
        this.register.TotalPCS = poToEdit.TotalPCS;
        this.register.TotalValue = poToEdit.TotalValue;
        this.register.Gender = poToEdit.Gender;
        this.register.ReOrder = poToEdit.ReOrder;

        this.selectedShops = this.purchaseOrderShops.filter(i => i.OrderIndex === poToEdit.OrderIndex).map(obj => obj);

        setTimeout(() => {
          this.ScrollToSelectedBranchRadio();

        },500);

        setTimeout(() => {
          this.ScrollToSelectedShopCheckbox();

        }, 1000);

        if (poToEdit.IsUserLocked) {
          this.employeeService.GetEmployeeInfoByUserId(poToEdit.UpdatedBy).subscribe((data: any) => {
            this.orderCreatedEmployeeInfo = data.Employee_Name + '-- Department:' + data.Department_Name;
          });
        };

        if (poToEdit.FileName1) {
          let file: OrderFilesWithURL = {
            OrderIndex: poToEdit.OrderIndex,
            FileColumnName: "FileName1",
            FileName: poToEdit.FileName1,
            FileURL: poToEdit.FilePath + encodeURIComponent(poToEdit.FileName1)
          }
          this.orderFiles.push(file);
        }
        if (poToEdit.FileName2) {
          let file: OrderFilesWithURL = {
            OrderIndex: poToEdit.OrderIndex,
            FileColumnName: "FileName2",
            FileName: poToEdit.FileName2,
            FileURL: poToEdit.FilePath + encodeURIComponent(poToEdit.FileName2)
          }
          this.orderFiles.push(file);
        }
        if (poToEdit.FileName3) {
          let file: OrderFilesWithURL = {
            OrderIndex: poToEdit.OrderIndex,
            FileColumnName: "FileName3",
            FileName: poToEdit.FileName3,
            FileURL: poToEdit.FilePath + encodeURIComponent(poToEdit.FileName3)
          }
          this.orderFiles.push(file);
        }
        if (poToEdit.FileName4) {
          let file: OrderFilesWithURL = {
            OrderIndex: poToEdit.OrderIndex,
            FileColumnName: "FileName4",
            FileName: poToEdit.FileName4,
            FileURL: poToEdit.FilePath + encodeURIComponent(poToEdit.FileName4)
          }
          this.orderFiles.push(file);
        }
        if (poToEdit.FileName5) {
          let file: OrderFilesWithURL = {
            OrderIndex: poToEdit.OrderIndex,
            FileColumnName: "FileName5",
            FileName: poToEdit.FileName5,
            FileURL: poToEdit.FilePath + encodeURIComponent(poToEdit.FileName5)
          }
          this.orderFiles.push(file);
        }
        
      };

    });
    this.ngxService.stop();
} 
  LoadGoodsInBranch(regionId): any {
    this.purchaseregisterservice.getGoodsInBranch(regionId).subscribe((data: any) => {
      this.goodsInBranchList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadShop(branchId): any {
    this.purchaseregisterservice.GetUnitShop(branchId).subscribe((data: any) => {
      this.shopList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadCurrency(): any {
    this.purchaseregisterservice.getCurrency().subscribe((data: any) => {
      debugger;
      this.currencyList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  getCurrencies(): any {
    return this.currencyList.map(c => c.CurrencyName);
  }

  LoadGender(): any {
    this.purchaseregisterservice.getGender().subscribe((data: any) => {
      debugger;
      this.genderList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }

  getGender(): any {
    return this.genderList.map(c => c.GenderName);
  }

  LoadSupplier(): any {
    this.purchaseregisterservice.getSupplier().subscribe((data: any) => {
      this.supplierList = data;
      this.register.SupplierId = this.defaultbrand;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadStatisticalPeriod(): any {
    this.purchaseregisterservice.getStatisticalPeriod().subscribe((data: any) => {
      this.statisticalPeriodList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  LoadIncoterm(): any {
    this.purchaseregisterservice.getIncoTerm().subscribe((data: any) => {
      this.incotermList = data;
    },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error);
      });
  }
  

  ClearRegisterFields(refreshGrid?: boolean): void {
    this.register.OrderIndex = "";
    this.register.OrderNo = "";
    this.register.OrderDiscount = "";
    this.register.Comment = "";    
    this.register.Incoterms = "";
    this.register.PaymentTerm = "";
    this.register.DeliveryFromTo = [this.bsValue, this.maxDate];
    this.register.SKU = "";
    this.register.InternalPONumber = "";
    this.register.Currency = "";
    this.register.TotalPCS = "";
    this.register.TotalValue = "";
    this.register.IsUserLocked = undefined;
    this.register.Gender = "";
    this.register.ReOrder = undefined;
    this.orderCreatedEmployeeInfo = "";
    this.register.fileUpload = "";    
    this.orderFiles = [];
    this.filesToUpload = [];
    this.register.POSupplierContactId = "";
    this.register.OrderTypeId = "";
    this.register.POSupplierId = "";
    if (this.orderIndexToEdit != "") {
      this.orderIndexToEdit = "";
      this.router.navigate(['poregistration'], { relativeTo: this.route.parent });
    }

    if (refreshGrid) {
      this.LoadShop(null);
      this.selectedShops = [];
      this.register.BranchId = "";
      // Get all shopCheckboxes
      const shopCheckboxes = Object.values(document.querySelectorAll<HTMLInputElement>('input[name="ShopId"]'));
      shopCheckboxes.map(c => c.checked = false);      

      this.LoadPurchaseOrders(null, null, null, this.register.BrandId, this.register.SeasonId, null, null);
    }

  }


  onBrandChange(event) {
    debugger;
    if (this.orderIndexToEdit == "") {
     this.ClearRegisterFields();
    }
    this.register.BrandId = event.target.value;
    if (this.orderIndexToEdit == "") {
      this.LoadPurchaseOrders(null, null, null, this.register.BrandId, this.register.SeasonId,
        (this.register.BranchId === undefined || this.register.BranchId == "") ? null : this.register.BranchId,
        (this.selectedShops.length === 0) ? null : this.selectedShops.map(function (e) {
          return e.ShopNo;
        }).join(', '));
    }
  }
  onStatChange(event) {
    var tempBrandId = this.register.BrandId;
    if (this.orderIndexToEdit == "") {
      this.ClearRegisterFields();
    }
    this.register.BrandId = tempBrandId;
    this.register.SeasonId = event.target.value;
    if (this.orderIndexToEdit == "") {
      this.LoadPurchaseOrders(null, null, null, this.register.BrandId, this.register.SeasonId,
        (this.register.BranchId === undefined || this.register.BranchId == "") ? null : this.register.BranchId,
        (this.selectedShops.length === 0) ? null : this.selectedShops.map(function (e) {
          return e.ShopNo;
        }).join(', '));
    }
  }
  OnGoodsInBranchChanges(id,event): any {
    debugger;
    this.register.BranchId = id;
    this.LoadShop(id);

    if (this.register.OrderIndex != "" || this.register.Currency != "" || this.register.TotalPCS != "" || this.register.TotalValue != "" || this.register.SKU != "") {
      this.selectedShops = [];
    }
    else {
      //this.ClearRegisterFields();
      this.LoadPurchaseOrders(null, null, null, this.register.BrandId, this.register.SeasonId, id, null);
      
    }
  }

  OnShopChanged(id, event): any {
    debugger;    
    if (!this.selectedShops.some(e => e.ShopNo === id)) {
      let poShop: PoShopModel = {
        ShopNo: id,
        OrderIndex: this.register.OrderIndex
      }
      this.selectedShops.push(poShop);
    } else {
      this.selectedShops.splice((this.selectedShops.findIndex((i) => {
        return (i.ShopNo === id );
      })),1);
    }
    debugger;
    var shopSelected = this.selectedShops.map(function (e) {
      return e.ShopNo;
    }).join(', ');

    if (this.register.OrderIndex != "" || this.register.Currency != "" || this.register.TotalPCS != "" || this.register.TotalValue != "" || this.register.SKU != "") {

    }
    else {
      this.LoadPurchaseOrders(null, null, null, this.register.BrandId, this.register.SeasonId, this.register.BranchId, shopSelected);
      //this.ClearRegisterFields();
      
    };
  }

  onIncotermChange(event): any {
    this.register.Incoterms = event.target.value;
  }


  onIsUserLockedChange(isChecked) {
    this.register.IsUserLocked = isChecked;
    this.isUserLockedCheckboxClicked = true; // Mark checkbox was clicked
  }

  onReOrderChange(isChecked) {
    debugger;
    this.register.ReOrder = isChecked;
    this.isReOrderCheckboxClicked = true; // Mark checkbox was clicked
  }

  // Differentiating the source of row selection or selection change
isEventFromReOrderCheckbox() {
  // Check if checkbox click triggered the event
  if (this.isReOrderCheckboxClicked) {
    this.isReOrderCheckboxClicked = false; // Reset flag
    return true;
  }
  return false;
  }

  // Differentiating the source of row selection or selection change
  isEventFromUserLockedCheckbox() {
    // Check if checkbox click triggered the event
    if (this.isUserLockedCheckboxClicked) {
      this.isUserLockedCheckboxClicked = false; // Reset flag
      return true;
    }
    return false;
  }


  
  ScrollToSelectedBranchRadio() {
    const radios = Object.values(document.querySelectorAll<HTMLInputElement>('input[type="radio"]'));
    const checkedRadio = [...radios].find(radio => radio.checked);
    if (checkedRadio) {
      checkedRadio.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }

  }
  ScrollToSelectedShopCheckbox() {
    // Get all shopCheckboxes
    const shopCheckboxes = Object.values(document.querySelectorAll<HTMLInputElement>('input[name="ShopId"]'));
    shopCheckboxes.map(c => c.checked = false);
    shopCheckboxes.map(c => {
      if (this.selectedShops.find(s => String(s.ShopNo) === c.defaultValue)) {
        c.checked = true;
      }
    });
    //
    // Get all radio buttons
    //const shopCheckboxes = Object.values(document.querySelectorAll<HTMLInputElement>('input[name="ShopId"]'));
    const checkedShops = [...shopCheckboxes].find(checkbox => checkbox.checked);
    if (checkedShops) {
      // Scroll to the checked radio button
      checkedShops.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }

  }

  onSubmit(form: NgForm): any {
    this.register.DeliveryFromDate = this.register.DeliveryFromTo[0];
    this.register.DeliveryToDate = this.register.DeliveryFromTo[1];

    if (this.register.OrderDiscount) {
      if (this.register.OrderDiscount.toString().includes(',')) {
        this.register.OrderDiscount = this.register.OrderDiscount.replace(',', '.');
      }
    }
    if (this.selectedShops.length > 0) {
      if (this.filesToUpload.length < 6) {
        this.uploadResult = "";
        let formData: FormData = new FormData();
        for (var i = 0; i < this.filesToUpload.length; i++) {
          formData.append('uploadedFiles', this.filesToUpload[i], this.filesToUpload[i].name);
        }
        this.register.PurchaseOrderShops = this.selectedShops;
        formData.append('poRegister', JSON.stringify(this.register));        
        this.ngxService.start();
        this.purchaseregisterservice.SavePORegistration(formData).subscribe((result: any) => {
          debugger;
          this.orderIndexToEdit = "";
          this.LoadPurchaseOrders(null, null, null, this.register.BrandId, this.register.SeasonId, this.register.BranchId, null);
          this.ClearRegisterFields();
          this.ngxService.stop();
          this.toastr.success(result)
          this.router.navigate(['poregistration'], { relativeTo: this.route.parent });     
          },
          error => {
            if (error.error) {
              this.ngxService.stop();
              this.toastr.error(error.error, 'Error');
            }
            else {
              this.ngxService.stop();
              this.toastr.error('Something went wrong', 'Error');
            }
          }
        );

      }
      else {
        this.toastr.warning("File upload exceeded more then 5.");
      }
    }
    else {
      this.toastr.error("Please Select at least a Shop");
    }    
   
  }



  upload() {
    if (this.filesToUpload.length > 5) {
      this.toastr.warning('Please select a maximum of 5 PDF files to upload!');
    }
  }

  fileChangeEvent(fileInput: any) {
    debugger;
    this.uploadResult = "";
    this.filesToUpload = <Array<File>>fileInput.target.files;
    for (let i = 0; i < this.filesToUpload.length; i++) {
      this.selectedFileNames.push(this.filesToUpload[i].name);
    }
  }

  RemoveImage(filename): void {
    bootbox.confirm("Do you want to delete the file?",
      (result) => {
        if (result) {
          this.purchaseregisterservice.RemoveOrderFile(filename.FileColumnName, filename.OrderIndex).subscribe((data: any) => {
            this.orderFiles.splice((this.orderFiles.findIndex((i) => {
              return (i.FileColumnName === filename.FileColumnName);
            })), 1);

            this.toastr.success("Successfully Deleted File!");
          },
            (err: HttpErrorResponse) => {
              this.toastr.error(err.error);
            });
        }
      });
  }

  DeleteOrder(OrderIndex): void {
    bootbox.confirm("Do you want to delete this Order?",
      (result) => {
        if (result) {
          this.purchaseregisterservice.DeletePurchaseOrder(OrderIndex).subscribe((data: any) => {
            this.orderIndexToEdit = null;
            this.ClearRegisterFields();
            this.LoadPurchaseOrders(null, null, null, this.register.BrandId, this.register.SeasonId, this.register.BranchId, null);            
            this.router.navigate(['poregistration'], { relativeTo: this.route.parent });
            this.toastr.success("Successfully Deleted Purchase Order!");
          },
            (err: HttpErrorResponse) => {
              this.toastr.error(err.error);
            });
        }
      });
  }


}

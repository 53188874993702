import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy, DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './auth/auth.guard';
import { AuthInterceptor } from './auth/auth.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppheaderComponent } from './components/appheader/appheader.component';
import { AppfooterComponent } from './components/appfooter/appfooter.component';
import { AppmenuComponent } from './components/appmenu/appmenu.component';
import { AppsettingsComponent } from './components/appsettings/appsettings.component';
import { EmployeemaintenanceComponent } from './employeemaintenance/employeemaintenance.component';
import { AppcontentComponent } from './components/appcontent/appcontent.component';
import { UsermaintenanceComponent } from './usermaintenance/usermaintenance.component';
import * as $ from 'jquery';
import { GlobalService } from './service/global.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UsergroupsComponent } from './usergroups/usergroups.component';
import { PurchaseregisterComponent } from './purchaseregister/purchaseregister.component';
import { UseraccessComponent } from './useraccess/useraccess.component';
import { UsergroupaccessComponent } from './usergroupaccess/usergroupaccess.component';
import { UserpreferencesComponent } from './userpreferences/userpreferences.component';
import { UseroverviewComponent } from './useroverview/useroverview.component';
import { AssignemployeeComponent } from './assignemployee/assignemployee.component';
import { RegionComponent } from './region/region.component';
import { UnitComponent } from './unit/unit.component';
import { SupplierComponent } from './supplier/supplier.component';
import { CurrencyComponent } from './currency/currency.component';
import { ReportComponent } from './report/report.component';
import { InfoComponent } from './info/info.component';
import { SmallreportviewComponent } from './smallreportview/smallreportview.component';
import { SmallreportsheetComponent } from './smallreportsheet/smallreportsheet.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { PurchaseditComponent } from './purchasedit/purchasedit.component';
import { CompareValidatorDirective } from './usermaintenance/compare-validator.directive';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { AppdashboardComponent } from './components/appdashboard/appdashboard.component';
import { SessionExpirationAlert, SessionInteruptService } from 'session-expiration-alert';
import { AppSessionInteruptService } from './service/app-session-interupt.service';
import { PurchaseregisterbulkComponent } from './purchaseregisterbulk/purchaseregisterbulk.component';
import { AgGridModule } from 'ag-grid-angular';
import { PurchaseregistergridbuttonComponent } from './components/purchaseregistergridbutton/purchaseregistergridbutton.component';
import { PoregistrationComponent } from './poregistration/poregistration.component';
import { PoUserLockedCheckboxComponent } from './components/po-user-locked-checkbox/po-user-locked-checkbox.component';
import { PoregistrationReportComponent } from './poregistration-report/poregistration-report.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ShopmaintenanceComponent } from './shopmaintenance/shopmaintenance.component';
import { SuppliercontactComponent } from './suppliercontact/suppliercontact.component';
import { PoregistrationwithcontactComponent } from './poregistrationwithcontact/poregistrationwithcontact.component';
import { PoregistrationReportWithcontactComponent } from './poregistration-report-withcontact/poregistration-report-withcontact.component';
import { PoReOrderCheckboxComponent } from './components/po-re-order-checkbox/po-re-order-checkbox.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    AppheaderComponent,
    AppfooterComponent,
    AppmenuComponent,
    AppsettingsComponent,
    EmployeemaintenanceComponent,
    AppcontentComponent,
    UsermaintenanceComponent,
    UsergroupsComponent,
    PurchaseregisterComponent,
    UseraccessComponent,
    UsergroupaccessComponent,
    UserpreferencesComponent,
    UseroverviewComponent,
    AssignemployeeComponent,
    RegionComponent,
    UnitComponent,
    SupplierComponent,
    CurrencyComponent,
    ReportComponent,
    InfoComponent,
    SmallreportviewComponent,
    SmallreportsheetComponent,
    PurchaseditComponent,
    CompareValidatorDirective,
    AppdashboardComponent,
    PurchaseregisterbulkComponent,
    PurchaseregistergridbuttonComponent,
    PoregistrationComponent,
    PoUserLockedCheckboxComponent,
    PoregistrationReportComponent,
    ShopmaintenanceComponent,
    SuppliercontactComponent,
    PoregistrationwithcontactComponent,
    PoregistrationReportWithcontactComponent,
    PoReOrderCheckboxComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxUiLoaderModule,
    NgSelectModule,
    AgGridModule.withComponents([
      PurchaseregistergridbuttonComponent,
      PoUserLockedCheckboxComponent,
      PoReOrderCheckboxComponent
    ]),
    SessionExpirationAlert.forRoot({ totalMinutes: 30 }),
    ToastrModule.forRoot({
      timeOut: 8000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressBar: true,
      closeButton: true,
    }),
    NgbModule.forRoot(),
    BsDatepickerModule.forRoot(),
    AngularMultiSelectModule,
  ],
  providers: [AuthGuard, GlobalService, DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: SessionInteruptService, useClass: AppSessionInteruptService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

/*useClass: HashLocationStrategy*/
//{ provide: LocationStrategy, useClass: HashLocationStrategy },
// { provide: LocationStrategy, useClass: PathLocationStrategy }

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellEditorParams, ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-po-re-order-checkbox',
  templateUrl: './po-re-order-checkbox.component.html',
  styleUrls: ['./po-re-order-checkbox.component.css']
})
export class PoReOrderCheckboxComponent implements ICellRendererAngularComp, OnDestroy {

  public params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  checkedHandler(event) {
    let checked = event.target.checked;
    this.params.context.componentParent.onReOrderChange(checked);
  }

  ngOnDestroy() {
    console.log(`Destroying Component`);
  }

}
